import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { addData, deleteData, editData, getData, patchData } from '../api/data'

export const fetchWorkers = createAsyncThunk('workers/fetch', async (token) => {
  try {
    const data = await getData('user/all', null, token)

    return data.sort((a, b) =>
      a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1
    )
  } catch (e) {
    throw new Error(e.message)
  }
})

export const addWorker = createAsyncThunk(
  'user/add',
  async ({ user, token }) => {
    try {
      const data = await addData('user/new', user, token)
      return data
    } catch (err) {
      throw err
    }
  }
)

export const editWorker = createAsyncThunk(
  'user/edit',
  async ({ user, token }) => {
    try {
      const data = await editData('user/edit', user, null, token)
      return data
    } catch (err) {
      throw err
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/delete',
  async ({ userId, token }) => {
    try {
      const data = await deleteData('user/delete', { id: userId }, token)
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  }
)

export const disableUser = createAsyncThunk(
  'user/disable',
  async ({ userId, token }) => {
    try {
      const data = await patchData('user/disable', { id: userId }, token)
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  }
)

export const deleteDayOff = createAsyncThunk(
  'dayoff/delete',
  async ({ dayOffId, userId, token }) => {
    try {
      await deleteData('dayoff/delete', { id: dayOffId }, token)

      return {
        id: dayOffId,
        userId: userId,
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const editDayOff = createAsyncThunk(
  'dayoff/edit',
  async ({
    dayOffId,
    dayOffHalfDay,
    dayOffDate,
    dayOffType,
    userId,
    token,
  }) => {
    try {
      await editData(
        'dayoff/edit',
        {
          id: dayOffId,
          halfDay: dayOffHalfDay,
          date: dayOffDate,
          type: dayOffType,
        },
        null,
        token
      )

      return {
        id: dayOffId,
        halfDay: dayOffHalfDay,
        date: dayOffDate,
        type: dayOffType,
        userId: userId,
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

const initialState = {
  loading: 'idle',
  data: [],
}

const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      return [...state, payload]
    },
  },
  extraReducers: {
    [fetchWorkers.pending]: (state) => {
      state.loading = 'pending'
    },
    [fetchWorkers.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = 'idle'
    },
    [fetchWorkers.rejected]: () => {
      console.error('fetch workers rejected!')
    },
    [addWorker.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload].sort((a, b) =>
        a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1
      )
    },
    [addWorker.rejected]: (state) => {
      console.log('error in adding a worker')
    },
    [editWorker.fulfilled]: (state, { payload }) => {
      const newState = payload
      state.data = newState
    },
    [editWorker.rejected]: (state) => {
      console.log('error in editing a worker')
      state.data = []
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      let newState = [...current(state.data)]
      const userIndex = current(state.data).findIndex(
        (user) => user._id === payload.id
      )
      newState.splice(userIndex, 1)
      state.data = newState
    },
    [deleteUser.rejected]: () => {
      console.error('delete project rejected!')
    },
    [deleteDayOff.fulfilled]: (state, { payload }) => {
      let newState = cloneDeep(state.data)

      const userIndex = newState.findIndex((user) => user.id === payload.userId)

      const newDaysOff = newState[userIndex].daysOff.filter(
        (dayOff) => dayOff._id !== payload.id
      )
      newState[userIndex].daysOff = newDaysOff
      state.data = newState
    },
    [editDayOff.fulfilled]: (state, { payload }) => {
      let newState = cloneDeep(state.data)

      const userIndex = newState.findIndex((user) => user.id === payload.userId)
      const dayOffIndex = newState[userIndex].daysOff.findIndex(
        (dayOff) => dayOff._id === payload.id
      )

      newState[userIndex].daysOff[dayOffIndex] = {
        ...newState[userIndex].daysOff[dayOffIndex],
        type: payload.type,
        halfDay: payload.halfDay,
        date: payload.date,
      }

      state.data = newState
    },
    [disableUser.fulfilled]: (state, { payload }) => {
      let newState = cloneDeep(state.data)
      const userIndex = current(state.data).findIndex(
        (user) => user._id === payload.id
      )
      newState[userIndex].disabled =
        newState[userIndex].disabled === true ? false : true
      state.data = newState
    },
  },
})

const { reducer, actions } = workersSlice

export const workersEntities = ({ workers }) => {
  const isLoading = workers.loading === 'pending'
  return { users: workers.data, isLoading }
}

export const { addUser } = actions

export default reducer

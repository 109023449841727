import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addData, getData } from '../api/data';
import { deleteUserOnCookie, saveUserOnCookie } from '../cookies/cookies';

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }) => {
    try {
      const userData = await addData('user/admin-login', { email, password });

      return userData;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  'user/me',
  async (token) => {
      try {
          const userData = await getData('user/me', null, token);
          return userData
      } catch (error) {
          throw error;
      }
  }
)

// export const setFavorites = createAsyncThunk('user/logout', async (favorites) => {
//   return favorites;
// });

export const logout = createAsyncThunk('user/logout', async (token) => {
  try {
    const userData = await addData('user/logout', null, token);

    return userData;
  } catch (err) {
    throw err;
  }
});

const initialState = { user: null, token: '' };

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginFromCookie: (state, { payload }) => {
      return payload;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      saveUserOnCookie(payload);

      return payload;
    },
    [logout.fulfilled]: () => {
      deleteUserOnCookie();
      return initialState;
    },
    [fetchUserInfo.fulfilled]: (state, {payload}) => {
      saveUserOnCookie(payload, false)
      return {...payload, token: state.token};
    },
    // [setFavorites.fulfilled]: (state, {payload}) => {
    //   alert(payload)
    // }
  },
});

const { reducer, actions } = loginSlice;

export const { loginFromCookie } = actions;

export default reducer;

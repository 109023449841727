import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getData, addData, editData, deleteData, patchData } from '../api/data';
import { cloneDeep } from 'lodash'


export const fetchProjects = createAsyncThunk(
  'projects/fetch',
  async (token) => {
    try {
      const res = await getData('project/all', null, token);
      return res;
    } catch (e) {
      throw new Error(e.message);
    }
  }
);

export const favoriteProject = createAsyncThunk(
  'projects/favorite',
  async ({project, token}) => {
    try {
      const res = await addData('project/favorite', project, token)

      return res
    } catch (error) {
      throw new Error(error.message)
    }
  }
)

export const addProject = createAsyncThunk(
  'projects/addOne',
  async ({ project, token }) => {
    try {
      const res = await addData('project/new', project, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const editProject = createAsyncThunk(
  'project/edit',
  async ({ project, token }) => {
    try {
      const res = await editData('project/edit', project, null, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const deleteProject = createAsyncThunk(
  'project/delete',
  async ({ projectId, token }) => {
    try {
      const res = await deleteData('project/delete', projectId, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const archiveProject = createAsyncThunk(
  'project/archive',
  async ({projectId, token}) => {
    try {
      const res = await patchData(`project/archive/${projectId}`, undefined, undefined, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
)

export const unarchvieProject = createAsyncThunk(
  'project/unarchive',
  async ({projectId, token}) => {
    try {
      const res = await patchData(`project/archive/${projectId}`, undefined, undefined, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
)

export const editHoursBank = createAsyncThunk(
  'project/hoursBank',
  async ({ data, params, token }) => {
    try {
      const res = await editData('project/hoursBank', data, params, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  'task/delete',
  async ({ taskId, projectId, token }) => {
    try {
      const res = await deleteData('task/delete', { taskId, projectId }, token);

      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  'notification/all',
  async (token) => {
    try {
      const res = await getData('notification/all', null, token);

      return res;
    } catch (error) {
      throw new Error(error.message);
    }
  }
)

export const markNotificationAsSeen = createAsyncThunk(
  'notification/seen',
  async ({token, notificationId}) => {
    try {
      await editData('notification/seen', {notificationId}, null, token);

      return {
        notificationId
      };
    } catch (error) {
      throw new Error(error.message);
    }
  }
)

export const createNewTag = createAsyncThunk(
  'tag',
  async ({tag, token}) => {
    try {
      const data = await addData('tag/new', { tag }, token)
      return data
    } catch (error) {
      throw new Error(error.message)      
    }
  }
)

export const fetchTags = createAsyncThunk(
  'tags',
  async (token) => {
    try {
      const data = await getData('tags', null, token);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
)

const initialState = {
  loading: 'idle',
  data: [],
  notifications: [],
  tags: [],
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // addProject: (state, { payload }) => {
    //   return [...state, payload];
    // },
    addProjectsData: (state, { payload }) => {
      return [...payload];
    },
  },
  extraReducers: {
    [fetchProjects.pending]: (state) => {
      state.loading = 'pending';
    },
    [fetchProjects.fulfilled]: (state, { payload }) => {
      state.loading = 'idle';
      state.data = payload;
    },
    [fetchProjects.rejected]: () => {
      console.error('fetch projects rejected!');
    },
    [addProject.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload].sort((a, b) =>
        a.company.name.toLowerCase() > b.company.name.toLowerCase() ? 1 : -1
      );
      console.log(state);
    },
    [addProject.rejected]: () => {
      console.error('add project rejected!');
    },
    [editProject.fulfilled]: (state, { payload }) => {
      const newState = [...current(state.data)];
      const projectIndex = current(state.data).findIndex(
        (project) => project._id === payload._id
      );
      newState[projectIndex] = payload;
      state.data = newState;
    },
    [editProject.rejected]: () => {
      console.error('edit project rejected!');
    },
    [deleteProject.fulfilled]: (state, { payload }) => {
      let newState = [...current(state.data)];
      const projectIndex = current(state.data).findIndex(
        (project) => project._id === payload.id
      );
      newState.splice(projectIndex, 1);
      state.data = newState;
    },
    [deleteProject.rejected]: () => {
      console.error('delete project rejected!');
    },
    [archiveProject.fulfilled]: (state, {payload}) => {
      let newState = [...current(state.data)];
      newState = cloneDeep(newState);
      newState = newState.map((project) => {
        if(project._id === payload.id) project.isArchived = true
        return project;
      })  
      state.data = newState;
    },
    [unarchvieProject.fulfilled]: (state, {payload}) => {
      let newState = [...current(state.data)];
      newState = cloneDeep(newState);
      newState = newState.map((project) => {
        if(project._id === payload.id) project.isArchived = false
        return project;
      })  
      state.data = newState;
    },
    [editHoursBank.fulfilled]: (state, { payload }) => {
      const newState = [...current(state.data)];

      const projectIndex = current(state.data).findIndex(
        (project) => project._id === payload._id
      );
      newState[projectIndex] = payload;
      state.data = newState;
    },
    [editHoursBank.rejected]: () => {
      console.error('edit project rejected!');
    },
    [deleteTask.fulfilled]: (state, { payload }) => {
      const newState = [...current(state.data)];

      const projectIndex = current(state.data).findIndex(
        (project) => project._id === payload._id
      );
      newState[projectIndex] = payload;
      state.data = newState;
    },
    [deleteTask.rejected]: () => {
      console.error('delete project rejected!');
    },
    [fetchNotifications.fulfilled]: (state, { payload }) => {
      state.notifications = payload;
    },
    [markNotificationAsSeen.fulfilled]: (state, {payload}) => {
      const notificationState = cloneDeep(state.notifications);

      const newNotifications = notificationState.map((notification) => {
        if(notification._id === payload.id) {
          notification.seen = true;
        }

        return notification
      })

      state.notifications = newNotifications;
    },
    [createNewTag.fulfilled]: (state, { payload }) => {
      const tagsState = cloneDeep(state.tags);

      const newTags = [...tagsState, payload];
      
      state.tags = newTags;
    },
    [fetchTags.fulfilled]: (state, { payload }) => {
      state.tags = payload
    }
    // [favoriteProject.fulfilled]: (state, {payload}) => {
    //   const favoriteProjects = cloneDeep(store.getState().login.user?.userData?.favorites?.projects) || []
    //   favoriteProjects.push(payload);
    //   store.dispatch(setFavorites(favoriteProjects))
    // }
  },
});

const { reducer, actions } = projectsSlice;

export const projectsEntities = ({ projects }) => {
  const isLoading = projects.loading === 'pending';
  return { projects: projects.data, isLoading };
};

export const { addProjectsData } = actions;

export default reducer;

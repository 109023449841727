// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import login from './login'
import companies from './companies'
import projects from './projects'
import workers from './workers'
import settings from './settings'

const rootReducer = {
  navbar,
  layout,
  login,
  companies,
  projects,
  workers,
  settings,
}

export default rootReducer

import axios from 'axios'

const url = process.env.REACT_APP_SERVER_URL

export const getData = async (query, params, token = null) => {
  const { data } = await axios.get(`${url}/${query}`, {
    params,
    headers: { Authorization: `Bearer ${token}` },
  })
  return data
}

export const addData = async (query, data, token = null, headersData = {}) => {
  try {
    const result = await axios.post(`${url}/${query}`, data, {
      headers: { Authorization: `Bearer ${token}`, ...headersData },
    })
    return result.data
  } catch (err) {
    throw err
  }
}

export const editData = async (
  query,
  data,
  params = {},
  token = null,
  headersData = {}
) => {
  try {
    const result = await axios.put(`${url}/${query}`, data, {
      params,
      headers: { Authorization: `Bearer ${token}`, ...headersData },
    })
    return result.data
  } catch (err) {
    throw err
  }
}

export const patchData = async (
  query,
  data,
  params = {},
  token = null,
  headersData = {}
) => {
  try {
    const result = await axios.patch(`${url}/${query}`, data, {
      params,
      headers: { Authorization: `Bearer ${token}`, ...headersData },
    })
    return result.data
  } catch (err) {
    throw err
  }
}

export const deleteData = async (query, data, token = null) => {
  try {
    const result = await axios.delete(`${url}/${query}`, {
      data,
      headers: { Authorization: `Bearer ${token}` },
    })
    return result.data
  } catch (err) {
    throw err
  }
}
